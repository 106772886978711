const {
    REACT_APP_ENV,
    REACT_APP_VERSION,
    REACT_APP_GTM,
    REACT_APP_PAYMENT_MODEL
} = (window as any).__env__;

type Environment = {
    REACT_APP_IDENTITYSERVER_API_BASE_URL: string;
    CLIENT_SECRET: string;
    REACT_APP_MAIN_BASE_URL: string;
    REACT_APP_PROFILE_BASE_URL: string;
    REACT_APP_ACCOUNTING_BASE_URL: string;
    REACT_APP_GRAPHQL_BASE_URL: string;
    REACT_APP_ASSETS_BASE_URL: string;
    REACT_APP_VERSION: string;
	REACT_APP_REPORTING_BASE_URL: string;
	REACT_APP_DIRECTUS_BASE_URL: string;
    REACT_APP_GTM: string;
    REACT_APP_PAYMENT_MODEL: string;
};

const envConfig: Environment = {
    REACT_APP_IDENTITYSERVER_API_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://auth-api.inplaytip.com/' : (REACT_APP_ENV === 'staging' ? 'https://auth-api-staging.inplaytip.com/' : 	'https://auth-api-dev.inplaytip.com/')),
    REACT_APP_PROFILE_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://pm-api.inplaytip.com/' : (REACT_APP_ENV === 'staging' ? 		'https://pm-api-staging.inplaytip.com/' : 			'https://pm-api-dev.inplaytip.com/')),
    REACT_APP_ACCOUNTING_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://acc-api.inplaytip.com/' : (REACT_APP_ENV === 'staging' ? 	'https://acc-api-staging.inplaytip.com/' : 			'https://acc-api-dev.inplaytip.com/')),
    REACT_APP_MAIN_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://inplaytip.com/' : (REACT_APP_ENV === 'staging' ? 					'https://staging.inplaytip.com/' : 					'https://dev.inplaytip.com/')),
    REACT_APP_GRAPHQL_BASE_URL: 'https://gateway' + (REACT_APP_ENV || '') + '-api.gainchanger.com/',
	// REACT_APP_ASSETS_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://assets-api.inplaytip.com/' : (REACT_APP_ENV === 'staging' ? 	'https://assets-api-staging.inplaytip.com/' : 		'https://assets-api-dev.inplaytip.com/')),
	REACT_APP_ASSETS_BASE_URL: 'https://assets-api.inplaytip.com/',
	REACT_APP_REPORTING_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://reporting-api.inplaytip.com/' : (REACT_APP_ENV === 'staging' ? 'https://reporting-api-staging.inplaytip.com/' : 'https://reporting-api-dev.inplaytip.com/')),
    REACT_APP_DIRECTUS_BASE_URL: (REACT_APP_ENV === 'prod' ? 'https://directus.inplaytip.com/' : (REACT_APP_ENV === 'staging' ? 'https://directus-staging.inplaytip.com/' : 'https://directus-dev.inplaytip.com/')),
    CLIENT_SECRET: 'aW5wbGF5dGlwc2RldmljZTp7OTM2Q0Q3NzQtMkE1Ni1BRTk3LTQyNjYtQUM0RTFFQTkxQjczfQ==',
    REACT_APP_VERSION: REACT_APP_VERSION,
    REACT_APP_GTM: REACT_APP_GTM,
    REACT_APP_PAYMENT_MODEL: REACT_APP_PAYMENT_MODEL,

};

export default envConfig;
