import FactoryService from "../FactoryService";

import {ResultCountriesInterface} from "../../Models/Country";
import ProfileBaseService from "../ProfileBaseService";

const factoryService = new FactoryService();

class AssetsService {
    private GET_LANGUAGE_REGION_ENDPOINT: string = "api/languages/region";

    async getRegion(): Promise<ResultCountriesInterface> {
        return await (
            await factoryService.create(ProfileBaseService)
        ).getAsJson(`${this.GET_LANGUAGE_REGION_ENDPOINT}`, false);
    }

}

export default new AssetsService();
