import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import translationsEN from './en/en.json';
import translationsPT from './pt/pt.json';

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage'],
};
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {translation: translationsEN},
            pt: {translation: translationsPT},
        },
        // lng: "en", // if you're using a language detector, do not define the lng option
        detection: DETECTION_OPTIONS,
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },

        supportedLngs: ['en', 'pt'],
    });
document.documentElement.lang = i18n.language; // <---- this line right here
