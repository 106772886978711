import FactoryService from "../FactoryService";

import {ResultCountriesInterface} from "../../Models/Country";
import AccountingBaseService from "../AccountingBaseService";

const factoryService = new FactoryService();

class SubscriptionService {
    private GET_CURRENT_SUBSCRIPTION_ENDPOINT: string = "api/Subscriptions/current";

    async getCurrentSubscription(): Promise<ResultCountriesInterface> {
        return await (
            await factoryService.create(AccountingBaseService)
        ).getAsJson(`${this.GET_CURRENT_SUBSCRIPTION_ENDPOINT}`, true);
    }

}

export default new SubscriptionService();
